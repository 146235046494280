var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import RcMenu, { ItemGroup } from 'rc-menu';
import classNames from 'classnames';
import omit from 'rc-util/lib/omit';
import EllipsisOutlined from '@ant-design/icons/EllipsisOutlined';
import SubMenu, { SubMenuProps } from './SubMenu';
import Item, { MenuItemProps } from './MenuItem';
import { ConfigConsumer } from '../config-provider';
import devWarning from '../_util/devWarning';
import { SiderContext } from '../layout/Sider';
import collapseMotion from '../_util/motion';
import { cloneElement } from '../_util/reactNode';
import MenuContext, { MenuTheme } from './MenuContext';
import MenuDivider from './MenuDivider';
export { MenuDividerProps } from './MenuDivider';
export { MenuItemGroupProps } from 'rc-menu';
class InternalMenu extends React.Component {
    constructor(props) {
        super(props);
        this.renderMenu = ({ getPopupContainer, getPrefixCls, direction }) => {
            const rootPrefixCls = getPrefixCls();
            const _a = this.props, { prefixCls: customizePrefixCls, className, theme, expandIcon } = _a, restProps = __rest(_a, ["prefixCls", "className", "theme", "expandIcon"]);
            const passedProps = omit(restProps, ['siderCollapsed', 'collapsedWidth']);
            const inlineCollapsed = this.getInlineCollapsed();
            const defaultMotions = {
                horizontal: { motionName: `${rootPrefixCls}-slide-up` },
                inline: collapseMotion,
                other: { motionName: `${rootPrefixCls}-zoom-big` },
            };
            const prefixCls = getPrefixCls('menu', customizePrefixCls);
            const menuClassName = classNames(`${prefixCls}-${theme}`, className);
            return (<MenuContext.Provider value={{
                    prefixCls,
                    inlineCollapsed: inlineCollapsed || false,
                    antdMenuTheme: theme,
                    direction,
                    firstLevel: true,
                }}>
        <RcMenu getPopupContainer={getPopupContainer} overflowedIndicator={<EllipsisOutlined />} overflowedIndicatorPopupClassName={`${prefixCls}-${theme}`} {...passedProps} inlineCollapsed={inlineCollapsed} className={menuClassName} prefixCls={prefixCls} direction={direction} defaultMotions={defaultMotions} expandIcon={cloneElement(expandIcon, {
                    className: `${prefixCls}-submenu-expand-icon`,
                })}/>
      </MenuContext.Provider>);
        };
        if (process.env.NODE_ENV !== 'production')
            devWarning(!('inlineCollapsed' in props && props.mode !== 'inline'), 'Menu', '`inlineCollapsed` should only be used when `mode` is inline.');
        devWarning(!(props.siderCollapsed !== undefined && 'inlineCollapsed' in props), 'Menu', '`inlineCollapsed` not control Menu under Sider. Should set `collapsed` on Sider instead.');
    }
    getInlineCollapsed() {
        const { inlineCollapsed, siderCollapsed } = this.props;
        if (siderCollapsed !== undefined) {
            return siderCollapsed;
        }
        return inlineCollapsed;
    }
    render() {
        return <ConfigConsumer>{this.renderMenu}</ConfigConsumer>;
    }
}
InternalMenu.defaultProps = {
    theme: 'light', // or dark
};
// We should keep this as ref-able
class Menu extends React.Component {
    render() {
        return (<SiderContext.Consumer>
        {(context) => <InternalMenu {...this.props} {...context}/>}
      </SiderContext.Consumer>);
    }
}
Menu.Divider = MenuDivider;
Menu.Item = Item;
Menu.SubMenu = SubMenu;
Menu.ItemGroup = ItemGroup;
export { MenuTheme, SubMenuProps, MenuItemProps };
export default Menu;
